import React, { useEffect, useState } from 'react';
import { axiosInstancePublic } from '../../api/axiosInstances';
import './Campanhas.css';
import botaoExpandir from '../../Images/arrow-texto-noticias.svg';
import logoErro from "../../Images/Logo-erro-campanhas.jpg"
import { BlocksRenderer} from '@strapi/blocks-react-renderer';
/* import microfone from '../../Images/microfone-campanhas.svg';*/

const BASE_URL = process.env.REACT_APP_BASE_URL;

const Campanhas = () => {
  const [campanhas, setCampanhas] = useState([]);

  useEffect(() => {
    axiosInstancePublic
      .get('/api/campaigns?populate=*')
      .then((response) => {
        const receivedData = response.data.data.map((item) => ({
          coverURL: item.attributes.cover?.data?.attributes?.url ? BASE_URL + item.attributes.cover.data.attributes.url : logoErro,
          altText: item.attributes.cover?.data?.attributes?.alternativeText || '',
          text: <BlocksRenderer content={item.attributes.text}/>,
          isExpanded: false,
        }));
        setCampanhas(receivedData);
      })
      .catch((error) => {
        console.error(error);
        setCampanhas([
          {
            coverURL: logoErro,
            altText: '',
            text: 'Erro ao carregar as campanhas, tente novamente mais tarde!',
            isExpanded: false,
          },
        ]);
      });
  }, []);

  const toggleExpandido = (index) => {
    setCampanhas((prevCampanhas) =>
      prevCampanhas.map((campanha, i) =>
        i === index ? { ...campanha, isExpanded: !campanha.isExpanded } : campanha
      )
    );
  };

  return (
    <div className="campanhas-container">
      <div className="titulo-campanhas">
        <h1>Campanhas</h1>
      </div>

      <div className="conteudo-campanhas-container">
        {campanhas.map((campanha, index) => (
          <div key={index} className="campanha">
            <div className="campanha-row" style={{
              flexDirection: index % 2 === 0 ? "row" : "row-reverse",  
            }}>
              <div className="texto-container-campanhas">
                <p
                  className={`texto-imagem-campanhas ${
                    campanha.isExpanded ? 'expandido' : ''
                  }`}
                >
                  {campanha.text}
                </p>
                <img
                  src={botaoExpandir}
                  className={`botao-expandir ${
                    campanha.isExpanded ? 'virado-baixo' : ''
                  }`}
                  onClick={() => toggleExpandido(index)}
                  alt="botao"
                />
              </div>

              <figure className="figura-campanhas-container">
                <div className="enquadro-imagem-campanhas">
                  <img
                    className="imagem-campanhas"
                    src={campanha.coverURL}
                    alt={campanha.altText}
                  />
                </div>
              </figure>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Campanhas;
