import React, { useEffect, useState } from 'react';
import { axiosInstancePublic } from '../../api/axiosInstances';
/* import copinho from '../../Images/copinho-noticias.svg'; */
import luzEsquerda from '../../Images/luz-esquerda-noticias.svg';
import luzDireita from '../../Images/luz-direita-noticias.svg';
import logoErro from "../../Images/Logo-erro-campanhas.jpg"
import './Noticias.css';
import botaoExpandir from '../../Images/arrow-texto-noticias.svg';
import { BlocksRenderer} from '@strapi/blocks-react-renderer';

const BASE_URL = process.env.REACT_APP_BASE_URL;

const Noticias = () => {
  const [noticias, setNoticias] = useState([]);

   useEffect(() => {
    axiosInstancePublic
      .get('/api/updates?populate=*')
      .then((response) => {
        const receivedData = response.data.data.map((item) => ({
          coverURL: item.attributes.cover?.data?.attributes?.url ? BASE_URL + item.attributes.cover.data.attributes.url : logoErro,
          altText: item.attributes.cover?.data?.attributes?.alternativeText || '',
          text: <BlocksRenderer content={item.attributes.text}/>,
          isExpanded: false,
        }));
        setNoticias(receivedData);
      })
      .catch((error) => {
        console.error(error);
        setNoticias([
          {
            coverURL: logoErro,
            altText: '',
            text: 'Erro ao carregar as notícias, tente novamente mais tarde!',
            isExpanded: false,
          },
        ]);
      });
  }, []);

  const toggleExpandido = (index) => {
    setNoticias((prevNoticias) =>
      prevNoticias.map((noticia, i) =>
        i === index ? { ...noticia, isExpanded: !noticia.isExpanded } : noticia
      )
    );
  };

  return (
    <div className="noticias-container">
      <div className="titulo-noticias">
        <h1>Notícias</h1>
      </div>

      <div className="conteudo-noticias-container">
        {noticias.map((noticia, index) => (
          <div key={index} className="noticia">
            <div
              className={`noticia-row ${index % 2 === 0 ? 'normal' : 'invertida'}`}
            >
              <figure className="figura-noticias-container">
                <div className="figura-noticias"></div>
                <div className="enquadro-imagem-noticias">
                  <img
                    className="imagem-noticia"
                    src={noticia.coverURL}
                    alt={noticia.altText}
                  />
                </div>
              </figure>

              <div className="texto-container-noticias">
                <p
                  className={`texto-imagem-noticias ${
                    noticia.isExpanded ? 'expandido' : ''
                  }`}
                >
                  {noticia.text}
                </p>
                <img
                  src={botaoExpandir}
                  className={`botao-expandir ${
                    noticia.isExpanded ? 'virado-baixo' : ''
                  }`}
                  onClick={() => toggleExpandido(index)}
                  alt="botao"
                />
              </div>
            </div>
          </div>
        ))}
      </div>

      <img src={luzEsquerda} className="luz-esquerda-noticias" alt="luz" />
      <img src={luzDireita} className="luz-direita-noticias" alt="luz" />
    </div>
  );
};

export default Noticias;
