import React, { useState, useEffect } from 'react';
import { axiosInstancePrivate } from '../../api/axiosInstances';
import './Popup.css';
import Logo from '../../Images/LOGO_POPUP.svg';
import CloseButton from '../../Images/Close-button.svg';

const enviaJson = async (email) => {
  const json = JSON.stringify({ data: { email: email } });
  try {
    const responseData = await axiosInstancePrivate.post(
      '/api/subscribers',
      json
    );
    return responseData;
  } catch (error) {
    throw error;
  }
};

const Popup = (props) => {
  const [message, setMessage] = useState(null);
  const [messageType, setMessageType] = useState(null);

  const pegaDados = async (event) => {
    event.preventDefault();
    let input = document.querySelector('#email');
    let email = input.value;
    try {
      const responseData = await enviaJson(email);
      setMessage(responseData.data.message);
      setMessageType('success');
    } catch (error) {
      setMessage(error.response.data.error.message);
      setMessageType('error');
    }
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Escape') {
        props.setTrigger(false);
      }
    };

    if (props.trigger) {
      document.body.style.overflow = 'hidden';
      document.addEventListener('keydown', handleKeyDown);
    } else {
      document.body.style.overflow = 'auto';
    }

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [props.trigger]);

  useEffect(() => {
    if (!props.trigger) {
      setMessage(null);
      setMessageType(null);
    }
  }, [props.trigger]);

  const handleOverlayClick = (event) => {
    if (event.target.className === 'overlay') {
      props.setTrigger(false);
    }
  };

  return props.trigger ? (
    <>
      <div className="overlay" onClick={handleOverlayClick}></div>
      <div className="popup-container">
        <div className="popup-item">
          <img className="logo-pop-up" src={Logo} alt="gars-on"></img>

          <img
            className="button"
            onClick={() => props.setTrigger(false)}
            src={CloseButton}
            alt="close"
          ></img>
        </div>

        <p>
          Faça seu <span>CADASTRO!</span> Vamos te alertar quando o app estiver
          no ar!
        </p>

        <form className="formulario-container" id="forms">
          <div className="formulario-item">
            <input
              type="email"
              placeholder="Email*"
              name="email"
              id="email"
            ></input>
            {message && (
              <p className={`message ${messageType}-message-popup`}>
                {message}
              </p>
            )}
          </div>
          <button onClick={pegaDados}>Cadastrar</button>
        </form>
      </div>
    </>
  ) : null;
};

export default Popup;
