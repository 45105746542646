import React, { useState, useEffect } from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Navigation, Pagination} from 'swiper/modules';import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';
import './CarrosselParceiros.css';

const CarrosselParceiros = ({ parceiros }) => {
    const [slidesPerView, setSlidesPerView] = useState(3);
    const [spaceBetween, setSpaceBetween] = useState(50);

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth <= 768) {
                setSlidesPerView(1);
                setSpaceBetween(20);
            } else if (window.innerWidth > 768 && window.innerWidth < 1200) {
                setSlidesPerView(2);
                setSpaceBetween(30); 
            } else {
                setSlidesPerView(3);
                setSpaceBetween(50); 
            }
        };

        window.addEventListener('resize', handleResize);
        handleResize();

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <Swiper
            modules={[Navigation, Pagination, Autoplay]}
            spaceBetween={spaceBetween}
            slidesPerView={slidesPerView}
            pagination={{ clickable: true }}
            navigation= {true}
            autoplay={{delay: 3000}}
            loop={parceiros.length >= 4}
        >
            {parceiros.map((content, key) => (
                <SwiperSlide key={key}>
                    <div className="card-item-parceiros">
                        <div className="card-header-parceiros">
                            <img src={content.img} alt={`parceiro-${key}`} />
                        </div>
                        <p dangerouslySetInnerHTML={{ __html: content.texto }}></p>
                    </div>
                </SwiperSlide>
            ))}
        </Swiper>
    );
};



export default CarrosselParceiros;
