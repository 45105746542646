import React from "react";
import {NavLink} from 'react-router-dom';
import './Navbar.css';
import Logo from '../../Images/LOGO_VETORIZADA.svg';
import { useState, useEffect } from "react";

const scrollToTop = () => {
    window.scrollTo({
        top: 0,
        behavior: "smooth"
    });
};



const Navbar = ({ onButtonClick }) => {

    const [menuAtivo, setMenuAtivo] = useState(false);

    const handleMenuClick = () => {
        setMenuAtivo(!menuAtivo);
    };
    const handleMenuItemClick = () => {
        setMenuAtivo(false); 
    };

    useEffect(() => {
        if (menuAtivo) {
            document.body.classList.add('menu-ativo');
        } else {
            document.body.classList.remove('menu-ativo');
        }

        return () => {
            document.body.classList.remove('menu-ativo');
        };
    }, [menuAtivo]);

    return(
        <nav>
            <div className="nav-container">
            <NavLink to='/'  onClick={scrollToTop}> <img className="nav-logo" src={Logo} alt="gars-on"></img></NavLink>
            <ul className={`nav-menu ${menuAtivo ? 'ativo' : ''}`}>
                <li  onClick={scrollToTop} ><NavLink className="nav-item" to='/' onClick={handleMenuItemClick}>Home</NavLink></li>
                <li  onClick={scrollToTop} ><NavLink className="nav-item" to='/dicas' onClick={handleMenuItemClick}>Dicas</NavLink></li>
                <li onClick={scrollToTop} ><NavLink className="nav-item" to='/noticias' onClick={handleMenuItemClick}>Notícias</NavLink></li>
                <li onClick={scrollToTop} ><NavLink className="nav-item" to='/campanhas' onClick={handleMenuItemClick}>Campanhas</NavLink></li>
                <li onClick={handleMenuItemClick}><button onClick={onButtonClick}>Baixe o App</button></li>
            </ul>
            
            <div className={`menu-icon ${menuAtivo ? 'ativo' : ''}`} onClick={handleMenuClick}>
                <span class="bar"></span>
                <span class="bar"></span>
                <span class="bar"></span>
            </div>
            </div>
        </nav>
        
    );
};


export default Navbar;